<template>
  <div class="LightGallery" @onCloseAfter="Closed();">
    <template v-if="!dynamic">
      <template v-for="(item,index) in items">
        <!--<a :href="((item.file || {}).url || '')" :data-sub-html="`<h4>${item.title}</h4><p>${item.description}</p>`" :style="makeUrlStyle(((item.file || {}).url || '').replace('/0/0/0/0/', '/200/0/0/0/'))">
          <img :src="((item.file || {}).url || '').replace('/0/0/0/0/', '/200/0/0/0/')" />
        </a>-->
        <a :style="makeUrlStyle(((item.file || {}).url || '').replace('/0/0/0/0/', '/200/0/0/0/'))" @click="selected(index)">
        </a>
      </template>
    </template>
  </div>
</template>
<script>
  import 'lightgallery.js/dist/js/lightgallery'
  import 'lg-rotate.js/dist/lg-rotate'
  import 'lg-zoom.js/dist/lg-zoom'
  import 'lg-autoplay.js/dist/lg-autoplay'
  export default {
    props: {
      dynamic: {
        type: Boolean,
        default: false
      },
      items: {},
      configs: {},
    },
    data() {
      return {
        randid: Number(Math.random().toString().substr(3, 8) + Date.now()).toString(36),
      }
    },
    watch: {
    },
    computed: {
      dynamicEls() {
        var els = []
        if (this.items) {
          for (var i in this.items) {
            var item = this.items[i]
            els.push({
              src: ((item.file || {}).url || ''),
              thumb: ((item.file || {}).url || '').replace('/0/0/0/0/', '/200/0/0/0/'),
              subHtml: `<h4>${item.title}</h4><p>${item.description}</p>`
            })
          }
        }
        return els
      }
    },
    mounted() {
      setTimeout(this.init,10)
    //  this.init()
    },
    created() {
    },
    destroyed() {
      if (window.lgData[this.$el.getAttribute('lg-uid')]) {
        window.lgData[this.$el.getAttribute('lg-uid')].destroy(true);
      }
    },
    methods: {
      init(index) {
        var o = {
          dynamic: true,
          dynamicEl: this.dynamicEls,
          download: false,
          closable: false,
          ...this.configs
        }
        if (index !== undefined) {
          o.index = index
        }
        lightGallery(this.$el, o)
      },
      onCloseAfter() {
        console.log('onCloseAfter')
      },
      makeUrlStyle(url) {
        return {
          'background-image': "url('" + url + "')"
        }
      },
      selected(index) {
        this.init(index)
      },
      Closed() {
        window.lgData[this.$el.getAttribute('lg-uid')].destroy(true);
        this.$emit('Closed')
      }
    }
  }
</script>

<style src="lightgallery.js/dist/css/lightgallery.css"></style>
<style scoped>
  .LightGallery {
    text-align: center
  }

    .LightGallery a {
      width: 100px;
      height: 100px;
      margin: 5px;
      background-color: #ccc;
      display: inline-block;
      background-position: center;
      background-size: cover;
    }

      .LightGallery a img {
        display: none
      }
</style>
