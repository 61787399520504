<template lang="html">
  <client-only placeholder="载入中...">
    <div style="height:200px;margin-bottom:15px;position:relative">
      <aMap ref="map" :ToolBars="false" :onMapReady="onMapReady" :mapConfig="{zoom: 15}"></aMap>
      <div style="position:absolute;left:0;right:0;top:0;bottom:0;z-index:200"></div>
    </div>
  </client-only>
</template>

<script>
  import aMap from '../Map/AMap'
  export default {
    components: {
      aMap
    },
    props: {
      item: {}
    },
    data() {
      return {
      }
    },
    mounted() {
    },
    methods: {
      onMapReady(aMap) {
        console.log(this.item)
        this.$refs.map.setCenterLngLat(this.item.gpsLng, this.item.gpsLat, 'gps')
        this.$refs.map.addMark('point', {
          gpsLng: this.item.gpsLng,
          gpsLat: this.item.gpsLat,
        })
      },
    }
  }
</script>
<style lang="css" scoped>
</style>
