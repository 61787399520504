<template lang="html">
  <div class="autoPano">
    <slot></slot>
    <div v-if="show" style="position:absolute;left:0;top:0;bottom:0;right:0;display:flex;justify-content:center;align-items:center">
      <Krpano ref="Pano" :pano-id="pano.guid" height="100%" :krpanoSettings="krpanoSettings" :on-krpano-ready="onKrpanoReady" :sceneVars="sceneVars" :onLoadComplete="onXmlComplete">
        <slot name="pano"></slot>
      </Krpano>
    </div>
  </div>
</template>

<script>
  import Krpano from '../Krpano'
  export default {
    components: {
      Krpano,
    },
    props: {
      pano: {
        type: Object,
        default: () => {
          return {}
        }
      },
      topoffset: {
        type: Number,
        default: 0
      },
      bottomoffset: {
        type: Number,
        default: 0
      },
      delaytime: {
        type: Number,
        default: 600
      },
    },
    data() {
      return {
        show: false,
        timeout: null,
        sceneVars: {
          'view.keep': true,
          keep: true,
          //'view.fovmin': 70,
          //'view.fovmax': 150,
          //'view.maxpixelzoom': 1.0,
          //'view.fovtype': 'MFOV',
        },
        krpanoSettings: {
          mwheel: false,
          //passQueryParameters: true
          vars: {
            'preview.type': 'grid(CUBE,64,64,512,0x000000,0x000000,0x000000);',
            'autorotate.enabled': true,
            'autorotate.speed': 7,
            'autorotate.accel': 1,
            'autorotate.waittime': 5
          }
        },
      }
    },
    mounted() {
      this.checkscroll()
      window.addEventListener('scroll', this.checkscroll)
    },
    destroyed() {
      window.removeEventListener('scroll', this.checkscroll)
    },
    methods: {

      onKrpanoReady(krpano) {
        this.krpano = krpano
        this.setContextmenu()
      },
      onXmlComplete() {
      },
      setContextmenu() {
        var menuXml = `
<krpano>
<contextmenu fullscreen="false" versioninfo="true">
    <item name="fs" caption="全屏浏览"     onclick="set(fullscreen,true);"      showif="fullscreen == false" />
    <item name="ef" caption="退出全屏"     onclick="set(fullscreen,false);"     showif="fullscreen == true" />
    <item name="cc" caption="切换控制模式" onclick="skin_changecontrolmode();"  separator="true" />
    <item name="nv" caption="一般视角"     onclick="skin_view_normal();"        showif="view.vlookatrange == 180" separator="true"      />
    <item name="fv" caption="鱼眼视角"     onclick="skin_view_fisheye();"       showif="view.vlookatrange == 180" devices="flash|webgl" />
    <item name="sv" caption="立体视角"     onclick="skin_view_stereographic();" showif="view.vlookatrange == 180" devices="flash|webgl" />
    <item name="av" caption="构架视角"     onclick="skin_view_architectural();" showif="view.vlookatrange == 180"                       />
    <item name="pv" caption="超广角视角"   onclick="skin_view_pannini();"       showif="view.vlookatrange == 180" devices="flash|webgl" />
    <item name="lp" caption="小行星视角"   onclick="skin_view_littleplanet();"  showif="view.vlookatrange == 180" devices="flash|webgl" />
  </contextmenu>


  <action name="skin_changecontrolmode">
    switch(control.mouse, moveto, drag);
    switch(control.touch, moveto, drag);
  </action>

  <action name="skin_view_look_straight">
    if(view.vlookat LT -80 OR view.vlookat GT +80,
      tween(view.vlookat, 0.0, 1.0, easeInOutSine);
      tween(view.fov,     100, distance(150,0.8));
      );
  </action>

  <action name="skin_view_normal">
    skin_view_look_straight();
    tween(view.architectural, 0.0, distance(1.0,0.5));
    tween(view.pannini,       0.0, distance(1.0,0.5));
    tween(view.distortion,    0.0, distance(1.0,0.5));
  </action>

  <action name="skin_view_fisheye">
    skin_view_look_straight();
    tween(view.architectural, 0.0,  distance(1.0,0.5));
    tween(view.pannini,       0.0,  distance(1.0,0.5));
    tween(view.distortion,    0.35, distance(1.0,0.5));
  </action>

  <action name="skin_view_architectural">
    skin_view_look_straight();
    tween(view.architectural, 1.0, distance(1.0,0.5));
    tween(view.pannini,       0.0, distance(1.0,0.5));
    tween(view.distortion,    0.0, distance(1.0,0.5));
  </action>

  <action name="skin_view_stereographic">
    skin_view_look_straight();
    tween(view.architectural, 0.0, distance(1.0,0.5));
    tween(view.pannini,       0.0, distance(1.0,0.5));
    tween(view.distortion,    1.0, distance(1.0,0.8));
  </action>

  <action name="skin_view_pannini">
    skin_view_look_straight();
    tween(view.architectural, 0.0, distance(1.0,0.5));
    tween(view.pannini,       1.0, distance(1.0,0.8));
    if(view.distortion LT 0.1,
      tween(view.distortion, 1.0, distance(1.0,0.8));
      );
  </action>

  <action name="skin_view_littleplanet">
    tween(view.architectural, 0.0, distance(1.0,0.5));
    tween(view.pannini,       0.0, distance(1.0,0.5));
    tween(view.distortion,    1.0, distance(1.0,0.8));
    tween(view.fov,           150, distance(150,0.8));
    tween(view.vlookat,        90, distance(100,0.8));
    add(new_hlookat, view.hlookat, 123.0);
    tween(view.hlookat, get(new_hlookat), distance(100,0.8));
  </action>
</krpano>
`
        this.krpano.call(`loadxml(${menuXml},null,MERGE|KEEPSCENES,null)`)
      },
      checkscroll(e) {
        if (this.timeout) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.onscroll()
          this.timeout = null
        }, this.delaytime, e)
      },
      onscroll(event) {
        var thisTop = this.$el.offsetTop
        var thisBottom = thisTop + this.$el.offsetHeight
        var screenTop = window.scrollY + this.topoffset
        var screenBottom = window.scrollY + window.innerHeight - this.bottomoffset
        if (screenBottom >= thisTop + 100 && thisBottom >= screenTop + 100 && this.show == false) {
          this.show = true
          this.$emit('turnable')
        }
        if (!(screenBottom >= thisTop && thisBottom >= screenTop) && this.show == true) {
          this.show = false
          this.$emit('disable')
        }
        console.log(this.show)
      },
    },
    watch: {
      ablePano(old) {
      }
    }
  }
</script>

<style lang="css" scoped>
  .autoPano {
    position: relative;
    min-height: 20vh;
  }
</style>
